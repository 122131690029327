.l-content-container {
    padding-right: 7.5%;
    padding-left: 7.5%;

    &.l-interior {
        h1, p {
            max-width: 100%;

            @media screen and (min-width: $screen-md-min) {
                margin-left: 0;
                max-width: 72%;
            }
        }
    }

    &.l-slim-container {
        @media screen and (min-width: $screen-md-min) {
            padding-right: 20%;
            padding-left: 20%;
        }
    }
}

.l-anchor-left {
    padding-right: 7.5%;
}

.l-anchor-right {
    padding-left: 7.5%;
}

.l-split-content {
    @media screen and (min-width: $screen-md-min) {
        display: flex;
        justify-content: space-between;
    }

    &-align-center {
        @media screen and (min-width: $screen-md-min) {
            align-items: center;
        }
    }

    &-equal-width > div {
        @media screen and (min-width: $screen-md-min) {
            flex: 1;

            &:last-child {
                margin-left: 120px;
            }
        }
    }
}

.l-constrain-content {
    @media screen and (min-width: $screen-md-min) {
        width: 70%;
    }
}
