.no-scroll {
    overflow: hidden;
}

html {
    box-sizing: border-box;
    background-color: #fff;

    *, *:before, *:after {
        box-sizing: inherit;
    }
}

html, body {
    @include font-sans;
    font-size: 14px;
    text-rendering: optimizeLegibility;

    @media screen and (min-width: 880px) {
        font-size: 15px;
    }

    @media screen and (min-width: 1080px) {
        font-size: 16px;
    }
}

img {
    max-width: 100%;
    height: auto;
}

strong, b {
    font-weight: 400;
}

i, em {
    font-style: italic;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

textarea {
    height: 100px;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

.o-content {
    p:not(:last-child) {
        margin-bottom: 24px;
    }

    a {
        color: #000;
        text-decoration: underline;
    }
}
