form {
    position: relative;
    margin-bottom: 36px;
    padding-bottom: 124px;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 24px;
        height: 4px;
        background: #000;
    }

    label {
        display: none;
    }

    input[type="text"], input[type="email"], textarea {
        @include font-sans;
        display: block;
        width: 100%;
        padding: 8px 0px 10px 0px;
        outline: none !important;
        border: 0;
        border-bottom: 2px solid #000;
        background: none;
        font-size: 12px;
        font-weight: 700;

        &.error {
            border-bottom-color: $c-error;
        }

        &::placeholder {
            color: #000;
            opacity: 1;
        }

        &.white {
            border-bottom-color: #fff;
            color: #fff;

            &::placeholder {
                color: #fff;
            }
        }
    }

    input[type="submit"] {
        cursor: pointer;
    }

    select {
        overflow: hidden;
        visibility: hidden;
        width: 0;
        height: 0;
        padding: 0;
        font-size: 0;
        line-height: 0;
        opacity: 0;
    }
}
