.c-tag {
    @include font-din;
    margin-bottom: 10px;
    display: inline-block;
    padding: 8px;
    background-color: #000;
    color: #fff;
    font-size: 12px;

    @media screen and (min-width: $screen-md-min) {
        margin-bottom: 22px;
        font-size: 16px;
    }
}
