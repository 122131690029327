.c-nav-overlay {
    overflow-y: auto;
    z-index: 2000;
    position: fixed;
    top: 65px;
    visibility: hidden;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    padding: 80px 0px 65px 30px;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.9);
    opacity: 0;
    transition: 0.2s linear;

    &.is-visible {
        visibility: visible;
        opacity: 1;
    }

    .social-links {
        a {
            display: inline-block;
            color: #fff;
            font-size: 16px;

            &:not(:first-child) {
                margin-left: 16px;
            }
        }
    }
}

.c-nav-toggle {
    position: relative;
    width: 24px;
    height: 19px;
    transition: transform 200ms linear;
    cursor: pointer;

    &:before, &:after {
        content: "";
        position: absolute;
        left: 0;
        transform: rotate(0deg);
        width: 100%;
        height: 3px;
        background: #fff;
        transition: all 0.2s linear;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }

    &-line {
        position: absolute;
        top: 8px;
        left: 0;
        width: 100%;
        height: 3px;
        background: #fff;
        opacity: 1;
        transition: opacity 0.2s linear;
    }

    &.collapse {
        &:before {
            top: 8px;
            transform: rotate(45deg);
        }

        &:after {
            bottom: 8px;
            transform: rotate(-45deg);
        }

        .c-nav-toggle-line {
            opacity: 0;
        }
    }
}

.c-site-nav, .c-secondary-nav {
    li {
        &:not(:last-child) {
            padding-bottom: 4px;
        }
    }

    a {
        color: #fff;
        text-transform: uppercase;
    }
}

.c-site-nav {
    position: relative;
    margin-bottom: 20px;
    padding-top: 56px;
    padding-bottom: 36px;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 24px;
        height: 2px;
        background: #fff;
    }

    &-label {
        @include font-din;
        display: inline-block;
        color: #fff;
        font-size: 56px;
        text-transform: uppercase;
        cursor: pointer;
    }

    &-submenu {
        overflow: hidden;
        max-height: 0;
        padding: 0;
        transition: all 0.2s linear;

        &.is-open {
            max-height: 700px;
            padding: 16px 0 20px;
        }

        a {
            @include font-din;
            color: rgba(255, 255, 255, 0.6);
            font-size: 16px;
            transition: color 150ms;

            &:hover {
                color: #fff;
            }
        }
    }
}

.c-secondary-nav {
    @include font-din;
    margin-bottom: 36px;
    font-size: 22px;
}
