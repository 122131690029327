.c-site-footer {
    padding-top: 64px;
    padding-bottom: 80px;
    background: #000;
    color: #fff;

    @media screen and (min-width: $screen-md-min) {
        display: flex;
        justify-content: space-between;
    }

    a {
        color: #fff;
    }

    ::placeholder {
        color: #fff !important;
    }

    &-newsletter {
        margin-bottom: 60px;

        @media screen and (min-width: $screen-md-min) {
            margin: 0 112px;
            width: 27%;
        }

        &-form {
            position: relative;
            margin-top: 40px;
            padding-bottom: 0;

            &:after {
                display: none;
            }

            &-fields {
                @media screen and (min-width: $screen-md-min) {
                    display: flex;
                    justify-content: space-between;
                }

                input[type="submit"] {
                    margin-top: 24px;

                    @media screen and (min-width: $screen-md-min) {
                        margin-top: 0;
                        margin-left: 19px;
                    }
                }
            }
        }

        &-heading {
            margin-bottom: 8px;
            font-family: "DINCond-Black";
            font-size: 24px;
            font-weight: 900;

            @media screen and (min-width: $screen-md-min) {
                font-size: 32px;
            }
        }
    }

    &-contact {
        margin-bottom: 64px;

        @media screen and (min-width: $screen-md-min) {
            margin-bottom: 0;
            width: 30%;
        }
    }

    &-heading {
        font-family: "DINCond-Black";
        font-size: 33px;
        font-weight: 900;
        text-transform: uppercase;

        @media screen and (min-width: $screen-md-min) {
            margin-bottom: 12px;
            font-size: 57px;
        }
    }

    &-address {
        margin-bottom: 34px;

        a {
            color: #fff;

            &:hover {
                opacity: 0.8;
            }
        }

        &-label {
            font-family: "DINCond-Black";
            font-weight: 900;
        }
    }

    &-copyright {
        font-family: "OpenSans-Regular";
        font-size: 9px;
    }

    &-social-links {
        margin-bottom: 34px;

        a {
            display: inline-block;
            color: #fff;

            &:not(:first-child) {
                margin-left: 12px;
            }
        }
    }
}
