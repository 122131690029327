.c-btn {
    @include font-din;
    display: inline-block;
    padding: 10px 24px;
    border: 4px solid #000;
    background: none;
    color: #000;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none !important;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 12px;
        margin-bottom: 16px;
    }

    &-white {
        border-color: #fff;
        color: #fff;
    }

    &-mini {
        padding: 8px 12px;
    }
}
