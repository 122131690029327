.c-hero {
    overflow: hidden;
    position: relative;
    display: flex;
    height: calc(100vh - 65px);
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;

    h1 {
        margin: auto;
        opacity: 1;
        transition: 0.2s linear;
    }

    &.fade-out h1 {
        opacity: 0;
    }

    &.half {
        height: 50vh;
    }

    &.with-video {
        &:before {
            content: "";
            z-index: -200;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #000;
        }

        &:after {
            content: "";
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.4);
        }
    }

    &-video {
        z-index: -100;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        min-width: 100%;
        height: auto;
        min-height: 100%;
    }

    &-arrow {
        position: absolute;
        bottom: 75px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 12px;
        height: 12px;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
    }
}
