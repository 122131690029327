.c-studio-spacer {
    margin-bottom: 64px;

    @media screen and (min-width: $screen-md-min) {
        margin-bottom: 120px;
    }
}

.c-studio {
    @media screen and (min-width: $screen-md-min) {
        display: flex;
        justify-content: space-between;
    }

    &-graphic {
        overflow: hidden;
        position: relative;
        display: block;
        height: 240px;

        @media screen and (min-width: $screen-md-min) {
            width: 142%;
            height: auto;
        }

        &-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 0.4s ease-in;
        }

        &:hover {
            .u-hover-link:after {
                width: 100%;
            }

            .c-studio-graphic-image {
                transform: scale(1.05);
            }
        }

        &-heading {
            @include font-din;
            margin-bottom: 6px;
            font-size: 24px;
            text-transform: uppercase;

            @media screen and (min-width: $screen-md-min) {
                font-size: 40px;
            }
        }

        &-content {
            color: #000;

            @media screen and (min-width: $screen-md-min) {
                width: 55%;
            }

            @media screen and (min-width: 1080px) {
                width: 45%;
            }

            @media screen and (min-width: $screen-lg-min) {
                width: 29%;
                max-width: 296px;
            }

            &-large {
                position: absolute;
                top: 0;
                right: 0;
                box-sizing: border-box;
                padding: 40px 4%;
                background: #fff;

                @media screen and (max-width: $screen-sm-max) {
                    display: none;
                }
            }

            &-small {
                padding: 40px 7.5% 30px;

                @media screen and (min-width: $screen-md-min) {
                    display: none;
                }
            }
        }

        &-description {
            margin-bottom: 4px;
        }
    }

    &-details {
        padding-right: 7.5%;
        padding-left: 7.5%;

        @media screen and (min-width: $screen-md-min) {
            padding: 120px 0 120px 80px;
        }

        &-attribute {
            &:not(:last-child) {
                margin-bottom: 10px;

                @media screen and (min-width: $screen-md-min) {
                    margin-bottom: 24px;
                }
            }

            &:last-child {
                position: relative;
                margin-bottom: 18px;
                padding-bottom: 20px;

                @media screen and (min-width: $screen-md-min) {
                    margin-bottom: 34px;
                    padding-bottom: 38px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 24px;
                    height: 4px;
                    background: #000;
                }
            }

            &-value {
                @include font-din;
                margin-bottom: 6px;
                font-size: 24px;

                @media screen and (min-width: $screen-md-min) {
                    font-size: 40px;
                }
            }
        }
    }

    &.c-studio-right {
        .c-studio-graphic-content, .c-studio-details {
            padding-left: 0;
        }

        .c-studio-graphic {
            @media screen and (min-width: $screen-md-min) {
                order: 2;
            }

            &-content {
                @media screen and (min-width: $screen-md-min) {
                    right: auto;
                    left: 0;
                    padding-left: 4%;
                }
            }
        }

        .c-studio-details {
            @media screen and (min-width: $screen-md-min) {
                padding-right: 80px;
                padding-left: 0;
            }
        }
    }
}
