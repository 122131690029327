h1, h2, h3 {
    @include font-din;
}

h1 {
    z-index: 100;
    position: relative;
    margin: 0px auto 16px auto;
    font-size: 40px;
    text-transform: uppercase;
    line-break: 1.2;

    @media screen and (min-width: $screen-md-min) {
        margin-bottom: 24px;
        font-size: 80px;
        line-height: 1.1;
    }
}

h2 {
    margin-bottom: 6px;
    font-size: 24px;
    line-break: 1.33;

    @media screen and (min-width: $screen-md-min) {
        margin-bottom: 14px;
        font-size: 56px;
        line-height: 1.14;
    }
}

h3 {
    margin-bottom: 32px;
    font-size: 19px;
    line-break: 1;

    @media screen and (min-width: $screen-md-min) {
        margin-bottom: 56px;
        font-size: 40px;
    }
}
