.c-services {
    @media screen and (min-width: $screen-md-min) {
        display: flex;
    }

    &-secondary {
        margin-right: 60px;
        display: block;
        flex-basis: 350px;
        flex-shrink: 0;

        @media (max-width: $screen-sm-max) {
            display: none;
        }

        @media (min-width: $screen-lg-min) {
            margin-right: 120px;
            flex-basis: 500px;
        }
    }

    &-main {
        max-width: 682px;
        padding: 0 7.5%;

        @media screen and (min-width: $screen-md-min) {
            padding: 60px 0 0;
        }
    }
}
