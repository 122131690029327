.c-site-header {
    z-index: 1500;
    top: 0;
    left: 0;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 65px;
    padding: 0 30px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #000;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.97) 0%, rgba(0, 0, 0, 0.95) 100%);

    img {
        display: block;
        width: 82px;
    }

    .nav-right {
        display: flex;
        align-items: center;

        .nav-cart {
            margin-top: 3px;
            margin-left: 30px;
        }
    }
}
