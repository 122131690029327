.c-studio-specs {
    position: relative;
    width: 200px;
    transition: 0.2s linear;

    &.is-fixed {
        top: 150px;
        height: calc(100vh - 70px);
    }

    &-inner {
        @media screen and (min-width: $screen-md-min) {
            position: absolute;
            top: 0;
            transition: 0.2s linear;
        }

        p {
            margin-bottom: 16px;
        }
    }

    &.position-bottom {
        position: absolute;
        top: auto;
        bottom: 0px;

        .c-studio-specs-inner {
            @media screen and (min-width: $screen-md-min) {
                top: auto;
                bottom: 0;
            }
        }
    }

    &-container {
        margin-bottom: 32px;

        @media screen and (min-width: $screen-md-min) {
            margin-right: 64px;
            margin-bottom: 0;
            width: 200px;
            flex-shrink: 0;
        }

        &.relative {
            position: relative;
        }
    }
}
